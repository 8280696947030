import React from 'react';
import SearchBar from '../StreamerList/SearchBar';

interface PaginationProps {
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  totalPages: number;
  searchTerm: string;
  setSearchTerm: (term: string) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  setCurrentPage,
  totalPages,
  searchTerm,
  setSearchTerm,
}) => {
  return (
    <div className="flex flex-col sm:flex-row justify-between items-center mt-6">
      <div className="flex items-center space-x-4 mb-4 sm:mb-0">
        <button
          className={`px-4 py-2 rounded-lg ${
            currentPage === 1
              ? 'bg-gray-700 text-gray-400 cursor-not-allowed'
              : 'bg-gray-700 text-gray-400 hover:bg-gray-600'
          }`}
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          ←
        </button>

        <button
          className={`px-4 py-2 rounded-lg ${
            currentPage === totalPages
              ? 'bg-gray-700 text-gray-400 cursor-not-allowed'
              : 'bg-gray-700 text-gray-400 hover:bg-gray-600'
          }`}
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          →
        </button>
      </div>

      <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />

      <div className="flex items-center space-x-4">
        <span className="text-gray-400">
          {((currentPage - 1) * 10) + 1}-{Math.min(currentPage * 10, totalPages * 10)} z{' '}
          {totalPages * 10}
        </span>
      </div>
    </div>
  );
};

export default Pagination;
