import React from 'react';

interface SearchBarProps {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ searchTerm, setSearchTerm }) => {
  return (
    <div className="flex items-center ml-4 w-full sm:w-auto">
      <input
        type="text"
        placeholder="Szukaj streamerów"
        onChange={(e) => setSearchTerm(e.target.value)}
        className="w-full sm:w-64 p-2 rounded-lg bg-gray-700 text-gray-100 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      />
    </div>
  );
};

export default SearchBar;
