import { useState } from "react";
import axios from "axios";

export const useStreamerData = (username: string) => {
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchStreamerData = async (nick: string) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/api/user/${nick}`);
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setData([]);
    } finally {
      setIsLoading(false);
    }
  };

  return { data, fetchStreamerData, isLoading };
};
