import React from 'react';
import { convertTime } from '../../utils/convertTime';

interface Streamer {
  streamer: string;
  watchtime: number;
}

interface StreamerListProps {
  data: Streamer[];
}

const StreamerList: React.FC<StreamerListProps> = ({ data }) => {
  return (
    <div>
      {data.length > 0 ? (
        <ul className="divide-y divide-gray-700">
          {data.map((item) => (
            <li key={item.streamer} className="py-4 flex justify-between items-center">
              <span className="text-lg">{item.streamer}</span>
              <span className="text-gray-400">{convertTime(item.watchtime)}</span>
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-gray-400 text-center">Brak danych do wyświetlenia.</p>
      )}
    </div>
  );
};

export default StreamerList;
