import { useNavigate } from 'react-router-dom';

interface SearchProps {
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  handleSearch: () => void;
}

const Search: React.FC<SearchProps> = ({ search, setSearch, handleSearch }) => {
  const navigate = useNavigate();

  const handleSearchWithNavigation = () => {
    handleSearch();
    navigate(`/${search.toLowerCase()}`);
  };

  return (
    <div className="max-w-4xl mx-auto mb-10">
      <div className="flex items-center space-x-4">
        <input
          type="text"
          onChange={(e) => setSearch(e.target.value.toLowerCase())}
          value={search}
          placeholder="Wpisz nazwę użytkownika"
          className="w-full p-3 rounded-lg bg-gray-800 text-gray-200 placeholder-gray-500 focus:outline-none focus:ring focus:ring-blue-500"
        />
        <button
          onClick={handleSearchWithNavigation}
          className="px-4 py-3 bg-blue-500 rounded-lg text-white hover:bg-blue-600"
        >
          Szukaj
        </button>
      </div>
    </div>
  );
};

export default Search;
