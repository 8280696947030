import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FaInfoCircle } from 'react-icons/fa';
import Chart from '../components/Chart/Chart';
import Search from '../components/Search/Search';
import StreamerList from '../components/StreamerList/StreamerList';
import Pagination from '../components/Pagination/Pagination';
import { useStreamerData } from '../hooks/useStreamerData';

const Home: React.FC = () => {
  const { username: paramUsername } = useParams<{ username?: string }>();
  const [username, setUsername] = useState<string>(paramUsername || "kaseko");
  const [search, setSearch] = useState<string>(paramUsername || "");
  const [searchText, setSearchText] = useState<string>(paramUsername || "kaseko");
  const [filterSearch, setFilterSearch] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 10;

  const { data, fetchStreamerData, isLoading } = useStreamerData(username);

  const lastSeenStreamer = data.length > 0 ? data[0] : null;

  useEffect(() => {
    fetchStreamerData(username);
    document.title = `Gdzie jest ${searchText}?`;
  }, [username]);

  const handleSearch = () => {
    setUsername(search);
    setSearchText(search);
    setCurrentPage(1);
  };

  const filteredData = data.filter((item) =>
    item.streamer.toLowerCase().includes(filterSearch.toLowerCase())
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const currentPageData = filteredData
    .sort((a, b) => b.watchtime - a.watchtime)
    .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-900 text-gray-100 p-6 font-sans flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-3xl font-bold text-gray-200 mb-4">Ładowanie danych...</h1>
          <div className="spinner-border animate-spin border-t-4 border-gray-300 border-solid rounded-full w-16 h-16 mx-auto" role="status">
            <span className="sr-only">Ładowanie...</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100 p-6 font-sans">
      <h1 className="text-4xl font-bold text-center mb-8">
        Gdzie jest {searchText}?
      </h1>

      <Search search={search} setSearch={setSearch} handleSearch={handleSearch} />

      {lastSeenStreamer && (
        <div className="text-center text-lg mb-6">
          <h2 className="text-xl font-medium text-gray-300 text-center mb-4">
            Ostatnio widziany u <strong className="text-white">{lastSeenStreamer.streamer}</strong> dnia {" "}
            {new Date(lastSeenStreamer.lastSeen).toLocaleDateString("pl-PL")} o{" "}
            {new Date(lastSeenStreamer.lastSeen).toLocaleTimeString("pl-PL", {
              hour: '2-digit',
              minute: '2-digit'
            })}
          </h2>
        </div>
      )}

      <div className="max-w-4xl mx-auto mb-10">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-2xl font-semibold">Ulubione kanały</h2>
          <div className="relative group">
            <FaInfoCircle className="text-gray-400 hover:text-white cursor-pointer" size={24} />
            <div className="absolute hidden group-hover:block bg-gray-800 text-white text-sm rounded p-2 shadow-lg -top-8 left-1/2 transform -translate-x-1/2">
              Dane zbierane są od 8.12.2024
            </div>
          </div>
        </div>

        <Chart data={filteredData} />
      </div>

      <div className="max-w-4xl mx-auto bg-gray-800 p-6 rounded-lg shadow-lg">
        <StreamerList data={currentPageData} />

        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          searchTerm={filterSearch}
          setSearchTerm={setFilterSearch}
        />
      </div>
    </div>
  );
};

export default Home;