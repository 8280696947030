import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { convertTime } from '../../utils/convertTime';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface ChartProps {
  data: { streamer: string, watchtime: number }[];
}

const Chart: React.FC<ChartProps> = ({ data }) => {
  const top5 = data
    .sort((a, b) => b.watchtime - a.watchtime)
    .slice(0, 5);

  const chartData = {
    labels: top5.map(item => item.streamer),
    datasets: [{
      label: "Watchtime (minuty)",
      data: top5.map(item => item.watchtime),
      backgroundColor: "rgba(59, 130, 246, 0.8)",
    }],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        bodySpacing: 0,
        callbacks: {
          label: (tooltipItem: any) => {
            const minutes = tooltipItem.raw;
            return convertTime(minutes);
          },
        },
        displayColors: false,
        titleAlign: 'center' as const,
        xAlign: 'center' as 'center',
      },
    },
  };

  return <Bar data={chartData} options={chartOptions} />;
};

export default Chart;
