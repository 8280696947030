export const convertTime = (minutes: number) => {
    const months = Math.floor(minutes / (60 * 24 * 30));
    const weeks = Math.floor((minutes % (60 * 24 * 30)) / (60 * 24 * 7));
    const days = Math.floor((minutes % (60 * 24 * 7)) / (60 * 24));
    const hours = Math.floor((minutes % (60 * 24)) / 60);
    const remainingMinutes = minutes % 60;
  
    const timeParts = [
      months > 0 ? `${months} miesiąc${months > 1 ? 'e' : ''}` : '',
      weeks > 0 ? `${weeks} tyg` : '',
      days > 0 ? `${days} dni` : '',
      hours > 0 ? `${hours} godz` : '',
      remainingMinutes > 0 ? `${remainingMinutes} min` : ''
    ];
  
    return timeParts.filter(Boolean).join(', ') || '0 min';
  };
  